body.login {
	background-color: #fff;

	div#login h1 a {
		background-image: url(../images/logo.png);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: 300px;
		height: 100px;
	}

	#login {
		padding: 5% 0px 60px;
	}
}

form#login, form#forgot {

	input,
	textarea {
		outline: none;
		border: solid 1px #4a6680;
		padding: .313em 0.5em;
		width: 100%;
		max-width: 350px;

		@media #{$small-only} {
			width: 100%;
		}
		
		&:focus {
			-webkit-box-shadow: 0 0 10px 0 rgba(74,102,128,0.5);
			box-shadow: 0 0 10px 0 rgba(74,102,128,0.5);
		}
	}

	label {
		width: 200px;
		display: block;
		margin-top: 10px;
	}

	label[for="user_login"] {

		input[type="text"] {
			margin-top: 10px;
		}
	}

	label[for="user_pass"] {

		input[type="password"] {
			margin-top: 10px;
		}
	}

	label[for="rememberme"] {
		input {
			width: auto;
		}
	}

	input[name="login"], input[name="forgot"] {
		display: inline-block;
		margin-right: 20px;
		text-transform: uppercase;
		text-align: center;
		font-size: 14px;
		line-height: 40px;
		background-color: #00274C;
		color: #fff;
		font-weight: 500;
		outline: 1px solid #fff;
		outline-offset: -5px;
		z-index:5;
		height: auto;
		position: relative;
		padding: 10px 20px;
		margin-top: 20px;
		margin-bottom: 30px;
		max-width: 200px;

		span {
			font-weight: 500;
			text-transform: uppercase;
		}

		&:hover {
			color: #fff;
			background-color: #4A6680;
			text-decoration: none;
		}
	}
}

form#loginform, form#lostpasswordform {

	border: 1px solid #CDD4DB;
	padding: 15px 20px;

	input,
	textarea {
		outline: none;
		border: solid 1px #4a6680;
		padding: .313em 0.5em;
		width: 100%;
		max-width: 350px;

		@media #{$small-only} {
			width: 100%;
		}
		
		&:focus {
			-webkit-box-shadow: 0 0 10px 0 rgba(74,102,128,0.5);
			box-shadow: 0 0 10px 0 rgba(74,102,128,0.5);
		}
	}

	label {
		width: 100%;
		display: block;
		margin-top: 10px;
	}

	label[for="user_login"] {

		input[type="text"] {
			margin-top: 10px;
		}
	}

	label[for="user_pass"] {

		input[type="password"] {
			margin-top: 10px;
		}
	}

	label[for="rememberme"] {
		input {
			width: auto;
		}
	}

	input[name="login"], input[name="forgot"], input[name="wp-submit"] {
		display: inline-block;
		margin-right: 20px;
		text-transform: uppercase;
		text-align: center;
		font-size: 14px;
		line-height: 40px;
		background-color: #00274C;
		color: #fff;
		font-weight: 500;
		outline: 1px solid #fff;
		outline-offset: -5px;
		z-index:5;
		float: left;
		height: auto;
		position: relative;
		padding: 10px 20px;
		margin-top: 20px;
		margin-bottom: 30px;

		span {
			font-weight: 500;
			text-transform: uppercase;
		}

		&:hover {
			color: #fff;
			background-color: #4A6680;
			text-decoration: none;
		}
	}
}