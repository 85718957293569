.slider {
	position: relative;
	background-color: #ccc;
	/*
	height: 565px;
	background-image: url(../images/bg-feature.jpg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	*/

	@media #{$small-only} {
		margin-top: 80px;
	}

	&:after {
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 368px;
		content: "";
		background-image: url(../images/overlay.png);
		background-repeat: repeat-x;
	}

	.soliloquy-container {
		img.soliloquy-image {
			width: 100%; 
		}
	}

	.soliloquy-caption  {
		// bottom: 45px !important;
		display: flex;
		height: 368px;
		background-image: url(../images/overlay.png);
		background-repeat: repeat-x;

		.soliloquy-caption-inside {
			margin-bottom: 45px;
			width: 100%;
			align-self: flex-end;
			align-content: center;
			background: none !important;

			h1 {
				margin-bottom: 20px;
				font-family: 'Playfair Display', serif;
				font-weight: 400;
				font-size: 36px;
				color: #fff;
			}

			.button {
				@extend %button;
				width: 190px !important;
				outline: none;
			}
		}
	}
}

main.page {

	.intro {
		background-color: #fff;
		background-image: url('data:image/gif;base64,R0lGODlh0AcBAIAAADBQbgAnTCH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwNjcgNzkuMTU3NzQ3LCAyMDE1LzAzLzMwLTIzOjQwOjQyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxNSAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4RTJCRDM3OTRGNkIxMUU1OUQ1RTkzMkFGOUU0ODBEMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4RTJCRDM3QTRGNkIxMUU1OUQ1RTkzMkFGOUU0ODBEMCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjhFMkJEMzc3NEY2QjExRTU5RDVFOTMyQUY5RTQ4MEQwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjhFMkJEMzc4NEY2QjExRTU5RDVFOTMyQUY5RTQ4MEQwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAAAAAAAsAAAAANAHAQAAAkSMj6nL7Q+jnLTai7PevPsPhuJIluaJpurKtu4LO8BM1/aN5/rO9/4PDAqHxKLxiEwql8ym8wmNSqfUqvWKzWq33O6uAAA7');
		background-repeat: repeat-y;
		background-position: center center;
		background-size: cover;

		.row {
			display: flex;
			// flex-wrap: wrap;

			@media #{$small-only} {
				display: inline-block;
			}

			section {
				display: flex;
				flex-wrap: wrap;
				padding-top: 20px;
				padding-bottom: 40px;

				&:nth-child(1) {
					background-color: #00274c;
				}

				&:nth-child(2) {
					background-color: #4a6680;
				}

				&:nth-child(3) {
					background-color: #30506e;
				}

				.content {
					flex-grow: 1;

					h1 {
						margin-bottom: 12px;
						text-transform: uppercase;
						font-family: 'Playfair Display SC', serif;
						font-weight: 400;
						font-size: 24px;
						color: #b2aa7e;
					}

					p {
						margin-bottom: 15px;
						font-size: 13px;
						line-height: 18px;
						color: #fff;
					}
				}

				a.home-button {
					align-self: flex-end;
					padding: 15px 30px;
					font-size: 14px;
					color: #b2aa7e;
					text-transform: uppercase;
					font-weight: normal;
					background-color: transparent;
					border: solid 1px #fff;
					margin-top: 20px;
					
					span {
						font-weight: 500;
					}

					&:hover {
						background-color: #fff;
					}

					&:first-of-type {
						margin-right: 30px;
					}
				}
			}
		}
	}

	.teasers {
		padding: 90px 0px 30px 0px;
		background-color: #fff;

		@media #{$medium-only} {
			padding: 30px 0px;
		}

		@media #{$small-only} {
			padding: 30px 0px;
		}

		section {

			padding-bottom: 60px;

			@media #{$medium-only} {
				padding-top: 40px;
			}

			@media #{$small-only} {
				padding-top: 40px;
			}

			&:first-child {

				h1 {
					margin-top: 0px;
					margin-bottom: 18px;
					font-size: 24px;
					line-height: 30px;
					color: #00264c;

					&:after {
						display: block;
						margin: 8px 0px 0px 0px;
						content: "";
						width: 160px;
						height: 1px;
						border-bottom: solid 1px #00264c;
					}
				}
			}

			h1 {
				margin: 14px 0px 12px 0px;
				text-transform: uppercase;
				font-family: 'Playfair Display SC', serif;
				font-weight: 400;
				font-size: 18px;
				color: #4a6680;
			}

			p {
				margin: 0px 0px 20px 0px;
				color: #000;
				font-size: 13px;
				line-height: 18px;

				a {
					font-weight: 500;
				}
			}

			.more {
				font-size: 12px;
				text-transform: uppercase;
				color: #30506e;

				span {
					font-weight: 500;
				}

				&:after {
					margin-left: 5px;
					content: "\f105";
					font-family: fontawesome;
				}
			}

			img {
				max-width: 30rem;
				width: 100%;
			}
		}

		.event-widget {

			li {
				list-style: none;
			}

			h2.widgettitle {
				display: none;
			}	
		}
	}
}

main.front section.full-width {
	padding: 40px 0;
	margin: 20px 0;

	@include full-width {
		background-color: rgb(20, 200, 200);
	}
}