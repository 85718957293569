#em-wrapper {
	padding: 0px 20px;

	th.event-time {
		text-align: left;
		font-weight: bold;
		border-bottom: 1px solid #dedede;
	}

	th.event-description {
		text-align: left;
		font-weight: bold;
		border-bottom: 1px solid #dedede;
	}

	.events-table {
		tbody {
			tr {
				td {
				padding: 10px 0px;
				}
			}
		}
	}
	.em-search-wrapper {
		display: none;
	}
}

.css-events-list {
	padding-bottom: 80px; 
}