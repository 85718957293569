%button {
	display: inline-block;
	margin-right: 20px;
	text-transform: uppercase;
	text-align: center;
	font-size: 14px;
	line-height: 40px;
	background-color: #00274C;
	color: #fff;
	font-weight: 500;
	outline: 1px solid #fff;
	outline-offset: -5px;
	width: 160px;
	z-index:5;
	position: relative;
	padding: 10px 20px;

	span {
		font-weight: 500;
		text-transform: uppercase;
	}

	&:hover {
		color: #fff;
		background-color: #4A6680;
		text-decoration: none;
	}

	&:last-child {
		margin-right: 0px;
	}

	&.gold {
		color: #00274c;
		background-color: #b3aa7e;
		width: 255px;
		border: solid 1px #fff;
		box-shadow: 0 0 0 6px #b3aa7e;
		outline: none;

		&:hover {
			background-color: #D4CA99;
			box-shadow: 0 0 0 6px #D4CA99;
		}
	}
}

// Hotfix for special gold buttons
a.button {
	@extend %button;
}



main.internal {
	position: relative;
	// z-index: -2;
	padding-bottom: 45px;
	background-color: #fff;
	color: #404040;
	font-size: 16px;
	line-height: 26px;


	section.banner {
		position: relative;
		margin-bottom: 70px;
		padding-top: 155px;
		padding-bottom: 50px;
		color: #fff;
		background-image: url('/wp-content/uploads/2015/02/bg-banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		&:before {
			position: absolute;
			top: 0px;
			left: 0px;
			display: block;
			width: 100%;
			height: 100%;
			content: "";
			background-color: rgba(#30506e,0.49);
		}

		h1 {
			position: relative;
			z-index: 1;
			margin: 0px;
			font-family: 'Playfair Display', serif;
			font-weight: 400;
			font-size: 50px;
			text-transform: uppercase;
			color: #fff;

			@media #{$small-only} {
				font-size: 36px;
			}
		}


	}

	h1 {
		margin: 0px 0px 22px 0px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 48px;
		line-height: 52px;
		color: #00274c;
	}

	h2 {
		margin: 0px 0px 15px 0px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 36px;
		line-height: 40px;
		color: #00274c;
	}

	h3 {
		margin: 0px 0px 15px 0px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		color: #00274c;
	}

	h4 {
		margin: 0px 0px 15px 0px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		color: #00274c;
	}

	h5 {
		margin: 0px 0px 15px 0px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		color: #00274c;
	}

	h6 {
		margin: 0px 0px 15px 0px;
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: #00274c;
	}

	p {
		margin-bottom: 30px;
	}
	
	strong {
		font-weight: bold;
	}

	ul {
		margin-bottom: 30px;
		margin-left: 25px;
		list-style-type: none;

		li {
			position: relative;
			margin-bottom: 10px; 

			&:before {
				position: absolute;
				left: -20px;
				top: 0;
				content: "\f105";
				font-family: fontawesome;
				font-size: 21px;
				color: #b3aa7e;
			}

			ol {
				margin-bottom: 0px;
				margin-left: 35px;
				// list-style-type: lower-alpha;
				counter-reset: listStyle;

				li {
					position: relative;
					counter-increment: listStyle;

					&:before {
						position: absolute;
						left: -20px;
						top: 0;
						content: counter(listStyle, lower-alpha);
						color: #b3aa7e;
						font-family: Roboto, sans-serif;
						font-size: 16px;
					}
				}
			}

			ul {
				margin-bottom: 0px;
				margin-left: 35px;
				list-style-type: disc;

				li {

					&:before {
						display: none;
					}
				}
			}
		}

		&.amicus-briefs-list {

			li {
				&:before {
					content: '\f15c';
					margin-right: 10px;
					font-size: .9em;
				}

				a {
					color: #00264c;
					

					&:hover {
						font-weight: bold;
						text-decoration: none;
					}
				}
			}
		}
	}

	ol {
		margin-bottom: 30px;
		margin-left: 25px;
		list-style-type: none;
		counter-reset: item;

		li {
			position: relative;

			&:before {
				position: absolute;
				left: -20px;
				top: 0;
				content: counter(item) ". ";
				counter-increment: item;
				color: #b3aa7e;
			}

			ol {
				margin-bottom: 0px;
				margin-left: 35px;
				// list-style-type: lower-alpha;
				counter-reset: listStyle;

				li {
					position: relative;
					counter-increment: listStyle;

					&:before {
						position: absolute;
						left: -20px;
						top: 0;
						content: counter(listStyle, lower-alpha);
						color: #b3aa7e;
					}
				}
			}

			ul {
				margin-bottom: 0px;
				margin-left: 35px;
				list-style-type: disc;

				li {

					&:before {
						display: none;
					}
				}
			}
		}
	}

	a {
		color: #b2aa7d;

		&:hover {
			text-decoration: underline;
		}

		&.button {
			@extend %button;
		}

		&.external:hover {

			&:after {
				content: '\f08e';
				font-family: fontawesome;
				margin-left: 10px;
				font-size: .7em;
				text-decoration: none;
				position: absolute;
			}

		}
	}

	a.more {
		font-size: 12px;
		text-transform: uppercase;
		color: #30506e;

		&:hover {
			text-decoration: none; 
		}

		span {
			font-weight: 500;
		}

		&:after {
			margin-left: 5px;
			content: "\f105";
			font-family: fontawesome;
		}
	}


	
	.gform_wrapper {
		
		form {
			
			.gform_body {
				margin: 0px;
				padding: 0px;
				
				.gform_fields {
					
					.gfield {
						
						.gfield_label {
							
							.gfield_required {
								
							}
						}
						
						.ginput_container {
							margin: 0px;
							
							input,
							textarea {
								outline: none;
								border: solid 1px #4a6680;
								padding: .313em 0.5em;

								@media #{$small-only} {
									width: 100%;
								}
								
								&:focus {
									-webkit-box-shadow: 0 0 10px 0 rgba(74,102,128,0.5);
									box-shadow: 0 0 10px 0 rgba(74,102,128,0.5);
								}
							}

							select {
								border: solid 1px #4a6680;
								margin-bottom: 8px;
								margin-top: 7px;
							}
						}
					}
				}
			}
			
			.gform_footer {
				margin: 0px;
				padding: 0px;
				
				.gform_button {
					margin: 0px;
					padding: 10px 50px;
					margin-top: 20px;
					font-size: 14px;
					font-weight: 700;
					text-transform: uppercase;
					background-color: #00274c;
					color: #fff;
					
					&:hover {
						background-color: $color-blue;
					}
				}
			}
		}
	}

	#gform_confirmation_message_2 {
		font-weight: bold;
		border-left: 4px solid #00274C;
		padding: 15px 20px;
		box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
	}



	.alignleft {
		float: left;
		margin: 0px 20px 0px 0px;
	}

	.alignright {
		float: right;
		margin: 0px 0px 0px 20px;
	}

	.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0px;
	}

	.wp-caption {
		margin-bottom: 20px;

		.wp-caption-text {
			font-size: 14px;
			line-height: 18px;
			color: #000;
		}
	}
	
	.full-width {
		margin: 40px 0px;
		padding: 30px 0px 30px 0px;
		font-size: 18px;
		color: #4a6680;
		@include full-width;
		
		&:before {
			background-image: url(../images/bg-pillars.jpg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		
		&:after {
			z-index: -1;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100%;
			background-color: rgba(204,211,217,0.8);
			
			@media screen and (min-width: #{$row-width}) {
				left: calc(-50vw + #{$row-width} / 2);
			}
		}
		
		h2 {
			margin: 0px;
			font-size: 24px;
			font-weight: 700;
			color: #00264c;
			text-align: center;
			text-transform: uppercase !important;
		}
	}
	
	.col {
		// border: solid 1px #f00;
	}

	p.user-info {
		line-height: 2em;
	}

	span.address-lines {
		padding-left: 65px;
		display: inline-block;
		line-height: 1.5em;
		text-indent: -65px;
	}


}

#content.widecolumn {

	background-color: #fff; 
	max-width: $row-width;
	margin: 0 auto;
	padding: 40px 20px;
	font-family: Roboto, sans-serif !important;

	

	h2 {
		font-size: 2em;
		margin-bottom: 20px;
	}

	p {
		line-height: 1.5em;
		margin-top: 20px;
	}

	a {
		color: #b2aa7d;

	}
	span.h3 {
		padding: 0; 
		font-weight: normal;
		font-family: inherit;
		font-size: 1em;
	}


}

.dataTables_wrapper {
	margin-bottom: 40px;
}

.dataTables_length {
		width: 300px;
	label {
		width: 300px;
		display: flex;
		padding-bottom: 10px;

		select {
			outline: none;
			border: 1px solid #979797;
			width: 50px;
			margin: 0px 5px;

		}
	}
}

.dataTables_filter {
	padding-bottom: 10px;
	text-align: left;

	input {
		outline: none;
		border: 1px solid #979797;
		border-radius: 7px;
		padding: 2px 10px;
	}
}

table.dataTable {
	border: 1px solid #979797;
	border-radius: 5px;

	thead {
		th {
			text-align: left;
			padding-left: 10px;
			border-bottom: 1px solid #979797;
		}
	}

	tbody {
		tr {
			background-color: transparent;
		}

		td {

			@media #{$small-only} {
				padding: 5px 2px;
				font-size: 10px;
				line-height: 14px;
			}

		}
	}
}