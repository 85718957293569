main.news-archive {

	padding-top: 2rem;

	h1.archive-title {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.posts {

		margin-bottom: 3rem;

		a.news-image {
			height: 10rem;
			width: 100%;
			background-size: cover;
			background-position: center;
			display: inline-block;
		}

		h2.news-title {
			line-height: 30px;
			margin-bottom: 2rem;

			a:hover {
				text-decoration: none;
			}
		}
	}

	p.not-available {
		margin-top: 3rem;
	}
}