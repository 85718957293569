body.page-template-page-about {

	section.officers {

		font-family: 'Playfair Display SC', serif;
		padding-left: 20px;
		padding-right: 20px;

		h1 {
			margin-bottom: 25px;
			font-family: 'Playfair Display SC', serif;
			font-weight: 400;
			font-size: 24px;
			line-height: 1em;
			color: #00264c;
			text-transform: uppercase;
			text-align: center;
			
			&:after {
				display: block;
				margin: 8px auto 0px auto;
				content: "";
				width: 128px;
				height: 1px;
				border-bottom: solid 1px #00264c;
			}
		}

		.officer-list-header {
			border-bottom: 1px solid #4a6680;
			margin-bottom: 20px;

			@media #{$small-only} {
				display: none;
			}

			h3 {
				font-size: 1em;
				font-weight: bold;
				margin-bottom: 5px;

				&:first-child:first-letter {
					font-size: 1.2em;
				}

			} 
		}

		.officer-row {
			margin-bottom: 10px;

			@media #{$small-only} {
				margin-bottom: 30px;
			}

			.officer-name {

				a:before {
						content:"\f007";
						font-family: fontawesome;
						margin-right: 10px;
				}
			}
		}

		a {
			color: #4a6680;

			&:hover {
				font-weight: bold;
				text-decoration: none;
			}
		}		
	}

	section.committee-section {

		h1 {
			margin-bottom: 25px;
			font-family: 'Playfair Display SC', serif;
			font-weight: 400;
			font-size: 24px;
			line-height: 1em;
			color: #00264c;
			text-transform: uppercase;
			text-align: center;
			
			&:after {
				display: block;
				margin: 8px auto 0px auto;
				content: "";
				width: 128px;
				height: 1px;
				border-bottom: solid 1px #00264c;
			}
		}

		h3 {
			text-align: center;
			text-transform: uppercase;
			color: #4a6680;
			font-size: 1em;
			margin-bottom: 10px;

			&:first-child:first-letter {
				font-size: 1.2em;
			}
		}

		p { 
			text-align: center;
		}

		.committee-col {
			margin-bottom: 30px;
		}
	}


}