body.search, body.error404 {

	main {

		form.searchform {

			margin-bottom: 30px;

			input[type="text"] { 
			    outline: none;
				border: solid 1px #4a6680;
				padding: 9px;
				margin-right: 10px;
			}

			button[type="submit"] {
				margin: 0px;
			    padding: 10px 50px;
			    margin-top: 20px;
			    font-size: 14px;
			    font-weight: 700;
			    text-transform: uppercase;
			    background-color: #00274c;
			    color: #fff;
			}
		}

		p {
			margin-bottom: 10px;
		}

		ol {
			
			margin-left: 50px;

			li {

				&:before {
					font-size: 2.2em;
					left: -50px;
					top: 5px;
					font-family: 'Playfair Display', serif;
				}
			}
		}
	}
}