.sponsors {
	padding: 20px 0px;
	text-align: center;
	background-color: #eeeeef;
	
	h1 {
		margin-bottom: 25px;
		font-family: 'Playfair Display SC', serif;
		font-weight: 400;
		font-size: 24px;
		color: #00264c;
		text-transform: uppercase;
		
		&:after {
			display: block;
			margin: 8px auto 0px auto;
			content: "";
			width: 128px;
			height: 1px;
			border-bottom: solid 1px #00264c;
		}
	}
	
	.logos {
		
		img {
			width: auto;
			height: auto;
			max-width: 200px;
			max-height: 50px;
			margin: 10px 20px;
			filter: grayscale(1);
			transition: all .5s linear;

			@media #{$small-only} {
				margin: 15px 20px;
			}
			
			&:hover {
				filter: grayscale(0);
			}
		}
	}
}

body > footer {
	text-align: center;
	
	.footer {
		padding: 30px 0px;
		background-color: #00274c;

		nav {
			margin-top: 12px;
			text-align: right;
			
			ul { 

				display: flex;
				justify-content: flex-end;

				@media #{$medium-only} {
					justify-content: center;
				}

				@media #{$small-only} {
					flex-wrap: wrap;
					justify-content: center;
				}


				li {

					> a {
						display: block;
						padding: 12px 15px;
						font-size: 13px;
						text-transform: uppercase;
						color: #fff;
						
						&:hover {
							background-color: #4a6680;
						}
					}
				}
			}
		}
	}
	
	.legal {
		padding: 10px 0px;
		font-size: 12px;
		color: #c6c7c9;
		
		a {
			margin-left: 5px;
			padding-left: 8px;
			color: #c6c7c9;
			border-left: solid 1px #c6c7c9;
		}
	}
}