body > header.desktop {
	position: relative;
	z-index: 99;
	box-shadow: 0 0px 16px 9px rgba(0,0,0,.28);

	@include with-touch-nav {
		display: none;
	}
	
	.banner {
		background-color: #30506e;
	}

	.logo {
		img {
			min-width: 170px;
		}
	}

	nav {
		text-align: right;

		ul > li {
			display: inline-block;
			
			&:first-child {
				margin-left: 0px;
			}

			> a {
				display: block;
			}

			> .sub-menu {
				display: none;
			}
		}

		&.primary {
			padding-top: 20px;
			background-color: #fff;
			height: 115px;
			
			ul > li {
				display: inline-block;
				margin-top: 30px;
				margin-left: 60px;

				&:first-child {
					padding-left: 0px;
				}

				@media #{$medium-only} {
					margin-left: 30px;
				}
	
				> a {
					text-transform: uppercase;
					font-family: 'Playfair Display SC', serif;
					font-weight: 400;
					font-size: 18px;
					color: #00264c;
				}
			}

			li.external a {

				&:hover {

					&:after {
						content: '\f08e';
						font-family: fontawesome;
						margin-left: 5px;
						font-size: .7em;
						text-decoration: none;
						position: absolute;
					}
				}
			}
		}
		
		&.secondary {
			
			display: flex;
			flex-direction: row-reverse;


			.search-box {
				display: flex;
				height: 32px;
				float: right;
				padding-top:3px;
				float: right;

				i {
					color: #fff;
					margin-left: 10px;
					margin-top: 2px;
					cursor: pointer;
				}

				form {
					width: 0px;
					overflow: hidden;
					transition: all 0.5s ease-out;

					.search-wrap {
						width: 250px;
					}

					input[type="text"] {
						background-color: #fff;
						padding: 3px 10px;
						margin-right: 5px;
						outline: none;
						margin-bottom: 10px;
					}

					input[type="submit"] {
						color: #fff;
					}
				}

				form.active {
					width: 250px;
					transition: all 0.5s ease-out;
				}
			}

			.menu-secondary-container {
 
				float: right;
			}

			ul {

				li {

					border-right: solid 1px #98a8b7;
					margin:0;
					padding: 8px 18px;
					margin-right: 0px;
					float: left;

						
						&:hover {
							background-color: #00274c;
							
						}
					
					> a {
						font-size: 16px;
						color: #fff;
					}

					span.username {
						color: #fff;
						display: block;
						margin-right: 15px;
					}

					a.user-info {
						&:before {
							content: '\f007';
							font-family: FontAwesome;
							margin-right: 10px;
						}
					}
				}

				li.user-info-li {
					display: flex;
				}
			}
			
		}
	}
}

body > header.mobile {
	
	display: none;
	padding: 10px 10px;
	width: 100%;
	height: 80px;
	background-color: #fff;
	position: fixed;
	top: 0;
	z-index: 2000;


	@include with-touch-nav {
		display: flex;
		justify-content: space-between;
	}

	// The menu toggle button
	.toggle-off-canvas {
		display: inline-block;
		font-weight: 100;
		font-size: 2em;;
		cursor: pointer;
		color: #002B56;

		&::before {
			font-family: FontAwesome;
			font-size: 1em;
			margin-right: 0.5em;
			vertical-align: top;
			content: "\f0c9";
		}
	}

	.logo {
		display: inline-block;

		img {
			width: 190px;
		}
	}
}

body > .mobile-off-canvas {
	position: fixed;
	top: 0;
	z-index: 105;
	width: 60vw;
	min-width: 280px;
	max-width: 400px;
	height: 100vh;
	background-color: white;
	overflow: scroll;
	transition: left 0.5s ease-in-out;
	left: -100%;
	padding-top:80px;
	padding-bottom: 80px;

	&.active {
		left: 0;
	}

	header {
		padding: 8px;
		margin-bottom: 16px;

		border-bottom: 1px solid black;
	}

	nav {
		padding: 0px;
		font-size: 20px;
		font-family: 'Playfair Display SC', serif;

		ul {
			margin: 0;
			padding: 0;

			li {
				display: block;
				padding: 15px 20px;
				width: 100%;
				border-bottom: 1px solid #002B56;
			}
		}

		ul#menu-primary {
			li:first-child {
				border-top: 1px solid #002B56;
			}

			li.external a {

				&:hover {

					&:after {
						content: '\f08e';
						font-family: fontawesome;
						margin-left: 5px;
						font-size: .7em;
						text-decoration: none;
						position: absolute;
					}
				}
			}
		}
	}

	li.user-info-li {

		&:before {
			content: '\f007';
			font-family: FontAwesome;
			margin-right: 10px;
		}

	}
}