/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.

	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-weight: 500;
	
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 700;
	
	font-family: 'Playfair Display SC', serif;
	font-weight: 400;
	
	font-family: 'Playfair Display', serif;
	font-weight: 400;
 */

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	background-color: #05182b;
}

a,
input,
textarea,
button {
	transition: all .2s ease-in-out;
}

a.more {
	font-size: 12px;
	text-transform: uppercase;
	color: #30506e;

	&:hover {
		text-decoration: none; 
	}

	span {
		font-weight: 500;
	}

	&:after {
		margin-left: 5px;
		content: "\f105";
		font-family: fontawesome;
	}
}